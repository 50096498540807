body {
  background-color: #eee;
}

.roboto-regular {
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
}

.roboto-medium {
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
}

.roboto-bold {
  font-family: 'Roboto', sans-serif;
  font-weight: 700;
}

.roboto-black {
  font-family: 'Roboto', sans-serif;
  font-weight: 900;
}

.hover-pointer:hover {
  cursor: pointer;
}

.banner {
  background-image: url('../src/assets/images/banner-background.jpg');
}

.quiz-background {
  background-image: url('../src/assets/images/quiz-background.png');
}
